$(document).ready(function () {
  var $recordContainer = $('.js-max-records-container');
  var childRecordSelector = '.js-max-records-item';
  var childRecordClassName = 'js-max-records-item';
  var addButtonSelector = '.js-max-records-add-button';
  var enableClass = 'is-active';

  $recordContainer.each(function () {
    var $container = $(this);
    var $addButton = $container.find(addButtonSelector);
    var maxRecordCount = parseInt($container.data('max-items'), 10) || 0;

    if (maxRecordCount > 0 && $addButton.length) {
      $addButton.on('click', () => {
        var currentRecordCount = $container.find(childRecordSelector).length;

        if (currentRecordCount === maxRecordCount) {
          $addButton.removeClass(enableClass);
        }
      });

      $container.bind('DOMNodeRemoved', function (e) {
        if ($(e.target).hasClass(childRecordClassName)) {
          var currentRecordCount = $container.find(childRecordSelector).length;

          if (currentRecordCount <= maxRecordCount) {
            $addButton.addClass(enableClass);
          }
        }
      });
    }
  });
});
